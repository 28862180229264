import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/default-page-layout.js";
import PageTitle from '../components/PageTitle';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle mdxType="PageTitle">Energetska samooskrba</PageTitle>
    <h2>{`S projekti energetske samooskrbe, v lokalnih okoljih ali regijah vzpostavljamo nove gospodarske aktivnosti in preprečimo odtekanje denarja iz teh območij.`}</h2>
    <p>{`Vsak EUR, ki ga danes porabimo za nakup nafte ali lesne biomase iz uvoza, odteče iz lokalnega okolja in konča v žepih posrednikov ter naftnih šejkov. S projekti lokalne ali regijske energetske samooskrbe poskrbimo, da ta sredstva ostanejo doma. To dosežemo s pomočjo lastnikov objektov, da zamenjajo energent (npr. nafto za lesno biomaso), obenem pa usposobimo lokalne dobavitelje energenta (lesna biomasa, elektrika iz malih HE).`}</p>
    <p>{`Na ta način se v lokalnem okolju oz. regiji ne doseže le znižanje cene energije in prehod na zelene energetske vire, ampak oblikuje tudi nova gospodarska aktivnost. To pa zmanjšuje njihovo odvisnost od zunanjih dejavnikov (energetski monopoli, gospodarske krize) in povečuje možnosti za bolj samostojno načrtovanje lastne prihodnosti. Prav možnost oblikovanja novih gospodarskih aktivnosti za lokalne »igralce« je tista, zaradi katere v Sončni zadrugi dajemo prednost lesni biomasi in vodi pred npr. toplotnimi črpalkami.`}</p>
    <p>{`Za lažjo ponazoritev projekta energetske samooskrbe, navajamo idejni projekt, ki smo ga pripravili za eno od slovenskih regij. Ta zajema izgradnjo štirih novih sistemov daljinskega ogrevanja in več samostojnih peči na lesne sekance v večjih javnih objektih. Za oskrbo z lesnimi sekanci se ustanovi lesni biomasni center, ki mu les dobavljajo lokalni kmetje-lastniki gozdov. Najbolj stabilna oblika organiziranja vseh v tak projekt vključenih akterjev (občine, lokalni kmetje, dobavitelji toplote), je lokalna zadruga, ker omogoča najlažje usklajevanje vseh interesov in izključuje vpliv kratkoročnih nihanj cen energentov; so pa možne tudi drugačne rešitve.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      